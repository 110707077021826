import {Alert, Box, Button} from "@mui/material";
import Header from "../../Header";
import React, {useEffect, useState} from "react";
import {addDiscounts, deleteDiscounts, getDiscounts, patchDiscounts} from "../../methods/TariffsData";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import useTableColorsScheme, {discountsScheme} from "../../UI/Tables/dash_scheme";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DisplaySettingsOutlinedIcon from "@mui/icons-material/DisplaySettingsOutlined";
import BasicModal from "../../UI/Modal/ModalTemplate";
import EditDiscountModal from "./EditDiscountModal";
import AskUser from "../../UI/Modal/AskUser";
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';

const updateDiscountHandler = async (token) => {
    return await getDiscounts(token);
}

export default function EditDiscount({token}) {
    const tableColorsScheme = useTableColorsScheme();
    const [discounts, setDiscounts] = useState([]);
    const [modalOptions, setModalOptions] = useState(null);
    const isModalOpen = Boolean(modalOptions);
    const [editAnswers, setEditAnswers] = useState(null);

    useEffect(() => {
        updateDiscountHandler(token).then(setDiscounts);
    }, [token]);

    const handleCloseModal = () => setModalOptions(null);

    const handleAddDiscount = (id) => {

        const fields = {
            "amount_from": 0,
            "amount_to": 0,
            "percent": 0
        };

        const addDiscountHandler = async (e) => {
            e.preventDefault();
            const data = new FormData(e.target);
            const response = await addDiscounts(token, data);

            if (response.severity === 'error') {
                setModalOptions({
                    title: `Добавить скидку`,
                    content: (
                        <EditDiscountModal
                            data={response.data || {
                                "amount_from": 0,
                                "amount_to": 0,
                                "percent": 0
                            }}
                            onsubmit={addDiscountHandler}
                            is_new
                            message={response.message}
                        />
                    ),
                });

                return;
            }

            setDiscounts(await updateDiscountHandler(token))
            handleCloseModal();
        }

        setModalOptions({
            title: "Добавить скидку",
            content: (
                <EditDiscountModal
                    data={fields}
                    onsubmit={addDiscountHandler}
                    is_new
                />
            ),
        });
    }
    const handleEditDiscount = (id) => {
        const editDiscountHandler = async (e) => {
            e.preventDefault();
            const data = new FormData(e.target);
            const response = await patchDiscounts(token, id, data);

            if (response.severity === 'error' || response.data) {
                setModalOptions({
                    title: `Редактировать скидку`,
                    content: (
                        <EditDiscountModal
                            data={response.data || discounts.find(d => d.id === id)}
                            onsubmit={editDiscountHandler}
                            message={response.severity === 'error' ? response : response.answer}
                        />
                    ),
                });
                if (response.data) setDiscounts(await updateDiscountHandler(token));
            }
        }

        const range = discounts.find(d => d.id === id);
        setModalOptions({
            title: `Редактировать скидку`,
            content: (
                <EditDiscountModal
                    data={range}
                    onsubmit={editDiscountHandler}
                />
            ),
        });
    }
    const handleDeleteDiscount = (id) => {

        const deleteDiscountHandler = async () => {
            // e.preventDefault();
            // const data = new FormData(e.target);

            const response = await deleteDiscounts(token, id);
            // console.log('\n ', response);

            console.log('\n response', response);

            setEditAnswers(response);
            if (response?.answer?.severity === "success") setDiscounts(await updateDiscountHandler(token));

            handleCloseModal();
        }

        setModalOptions({
            title: `Вы уверены что хотите удалить скидку ${id}?`,
            content: (<AskUser
                success={deleteDiscountHandler}
                reject={handleCloseModal}
            />),
        });
    }

    const columns = [
        ...discountsScheme,
        {
            field: "edit",
            align: "center",
            headerName: "",
            headerAlign: "center",
            width: 200,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    startIcon={<DisplaySettingsOutlinedIcon/>}
                    color="warning"
                    onClick={() => handleEditDiscount(params.row.id)}
                >
                    Редактировать
                </Button>
            ),
        },
        {
            field: "delete",
            align: "center",
            headerName: "",
            headerAlign: "center",
            width: 200,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    startIcon={<DeleteRoundedIcon/>}
                    color="error"
                    onClick={() => handleDeleteDiscount(params.row.id)}
                >
                    Удалить
                </Button>
            ),
        },
    ];

    return (
        <Box className="m-5 flex flex-col">
            {isModalOpen && (
                <BasicModal
                    open={isModalOpen}
                    handleClose={handleCloseModal}
                    title={modalOptions?.title}
                >
                    {modalOptions?.content}
                </BasicModal>
            )}
            <Header title="Тарифы" subtitle="Управление диапазонами скидок на сотрудников"/>
            <Button
                variant="contained"
                color="warning"
                onClick={handleAddDiscount}
                startIcon={<AddchartOutlinedIcon/>}
                className={`w-max`}
            >
                Добавить скидку
            </Button>
            {(editAnswers?.message) && <Alert severity={editAnswers.severity || "info"}>{editAnswers.message}</Alert>}
            {discounts.length > 0 &&
                <Box height="70vh" sx={tableColorsScheme}>
                    <DataGrid
                        rows={discounts}
                        columns={columns}
                        getRowId={(row) => row.id}
                        components={{Toolbar: GridToolbar}}
                    />
                </Box>
            }
        </Box>
    );
}