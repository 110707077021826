import React, {useEffect, useMemo, useState} from "react";
import {getAllCompanies} from "../../methods/getCompanies";
import DashTable from "../../UI/Tables/DashTable";
import {companyScheme} from "../../UI/Tables/dash_scheme";
import {Button} from "@mui/material";
import axios from "axios";
import {headersRequests} from "../../methods/Settings";
import BasicModal from "../../UI/Modal/ModalTemplate";
import AskUser from "../../UI/Modal/AskUser";

export default function Companies({token}) {

    const [companies, setCompanies] = useState(null);

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = (company) => {
        setIsOpen(true);
        setSelectedCompany(company);
    }

    useEffect(() => {

        const getData = async () => {

            const updateGetCompanies = await getAllCompanies(token);
            const addedToken = updateGetCompanies.map(c => ({...c, token}))
            const updateCompanies = addedToken
                .sort((a, b) => a.id - b.id)
            ;
            // console.log('\n updateCompanies', updateCompanies);
            setCompanies(updateCompanies);
        }

        getData();
    }, [token]);

    const columns = useMemo(() => {
        return companyScheme.map(company => {
            if (company.field === 'delete') {
                return {
                    ...company,
                    renderCell: (params) => {
                        const company = params.row;
                        return (
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => handleOpen(company)}
                            >
                                Удалить
                            </Button>
                        );
                    }
                }
            }
            return company
        })
    }, [companyScheme])

    return (
        <>
            {companies && <DashTable
                data={companies}
                label={"Список компаний"}
                columns={columns}
            />}
            <BasicModal
                open={isOpen}
                title={'Вы уверены, что хотите удалить компанию?'}
                handleClose={() => setIsOpen(false)}
            >
                <AskUser
                    success={() => {
                        axios.delete(`${process.env.REACT_APP_ERP_API_URL}/company/${selectedCompany?.id}/`, headersRequests('post', selectedCompany?.token)).then(() => window.location.reload())
                    }}
                    reject={() => setIsOpen(false)}
                />
            </BasicModal>
        </>
    );
}