import React, {useEffect, useMemo, useState} from "react";
import {getAllUsers} from "../../methods/getCompanies";
import DashTable from "../../UI/Tables/DashTable";
import {usersScheme} from "../../UI/Tables/dash_scheme";
import {Button} from "@mui/material";
import BasicModal from "../../UI/Modal/ModalTemplate";
import AskUser from "../../UI/Modal/AskUser";
import axios from "axios";
import {headersRequests} from "../../methods/Settings";

export default function AllUsers({token}) {

    const [, setCompanies] = useState(null); //companies
    const [allUsers, setAllUsers] = useState(null);

    const [selectedUser, setSelectedUser] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = (user) => {
        setIsOpen(true);
        setSelectedUser(user);
    }

    useEffect(() => {

        const getAllUsersHandler = async () => {

            const updateAllUsers = await getAllUsers(token);
            // console.log('\n getAllUsersHandler updateAllUsers', updateAllUsers);
            setCompanies(updateAllUsers.companies);
            const updateUsers = updateAllUsers?.users?.map(user => {

                user.company_name = updateAllUsers?.companies?.find(company => company.id === user.company_id)?.name;
                user.token = token;

                return (user);
            });
            // console.log('\n updateUsers', updateUsers);
            setAllUsers(updateUsers);
        }

        getAllUsersHandler();
    }, [token]);

    const columns = useMemo(() => {
        return usersScheme.map(user => {
            if (user.field === 'delete') {
                return {
                    ...user,
                    renderCell: (params) => {
                        const user = params.row;
                        return (
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => handleOpen(user)}
                            >
                                Удалить
                            </Button>
                        );
                    }
                }
            }
            return user
        })
    }, [usersScheme])

    return (
        <>
            {allUsers && <DashTable
                data={allUsers}
                label={"Список всех пользователей"}
                columns={columns}
            />}
            <BasicModal
                open={isOpen}
                title={'Вы уверены, что хотите удалить пользователя?'}
                handleClose={() => setIsOpen(false)}
            >
                <AskUser
                    success={() => {
                        axios.delete(`${process.env.REACT_APP_ERP_API_URL}/users/${selectedUser.id}/`, headersRequests('get', selectedUser?.token)).then(() => window.location.reload())
                    }}
                    reject={() => setIsOpen(false)}
                />
            </BasicModal>
        </>
    );
}
