import React, {useEffect, useMemo, useState} from "react";
import {getAllProjects} from "../../methods/getCompanies";
import DashTable from "../../UI/Tables/DashTable";
import {projectsScheme, usersScheme} from "../../UI/Tables/dash_scheme";
import AskUser from "../../UI/Modal/AskUser";
import axios from "axios";
import {headersRequests} from "../../methods/Settings";
import BasicModal from "../../UI/Modal/ModalTemplate";
import {Button} from "@mui/material";
export default function AllProjects({token}) {

    const [, setCompanies] = useState(null); //companies
    const [allProjects, setAllProjects] = useState(null);

    const [selectedProject, setSelectedProject] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = (project) => {
        setIsOpen(true);
        setSelectedProject(project);
    }

    useEffect(() => {

        const getAllProjectsHandler = async () => {

            const updateAllProjects = await getAllProjects(token);
            // console.log('\n getAllProjectsHandler updateAllUsers', updateAllProjects);
            setCompanies(updateAllProjects.companies);
            setAllProjects(updateAllProjects?.projects.map(p => ({...p, token})));
        }

        getAllProjectsHandler();
    }, [token]);

    const columns = useMemo(() => {
        return projectsScheme.map(project => {
            if (project.field === 'delete') {
                return {
                    ...project,
                    renderCell: (params) => {
                        const project = params.row;
                        return (
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => handleOpen(project)}
                            >
                                Удалить
                            </Button>
                        );
                    }
                }
            }
            return project
        })
    }, [projectsScheme])

    return (
        <>
            {allProjects && <DashTable
                data={allProjects}
                label={"Список всех проектов"}
                columns={columns}
            />}
            <BasicModal
                open={isOpen}
                title={'Вы уверены, что хотите удалить проект?'}
                handleClose={() => setIsOpen(false)}
            >
                <AskUser
                    success={() => {
                        axios.delete(`${process.env.REACT_APP_ERP_API_URL}/company/${selectedProject?.company_id}/projects/projects/${selectedProject?.id}/`, headersRequests('post', selectedProject?.token)).then(() => window.location.reload())
                    }}
                    reject={() => setIsOpen(false)}
                />
            </BasicModal>
        </>
    );
}
