import React, {useEffect, useState} from "react";
import {Alert, Box, Button} from "@mui/material";
import Header from "../../Header";
import {DataGrid} from "@mui/x-data-grid";
import {tariffsEditScheme, useTableColorsScheme} from "../../UI/Tables/dash_scheme";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import BasicModal from "../../UI/Modal/ModalTemplate";
import DetailTariffs from "./DetailTarifs";
import {
    addTariffsTemplate,
    deleteTariffsTemplate,
    getTariffsTemplate,
    patchTariffTemplate
} from "../../methods/TariffsData";
import AskUser from "../../UI/Modal/AskUser";
import AddCardIcon from '@mui/icons-material/AddCard';
import AddTariffTemplate from "./AddTariffTemplate";

export default function EditTariffs({token}) {

    const tableColorsScheme = useTableColorsScheme();
    const [tariffs, setTariffs] = useState([]);
    const [modalOptions, setModalOptions] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [answer, setAnswer] = useState(null);
    const [message, setMessage] = useState(null);
    const [modalType, setModalType] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            const updateTariffs = await getTariffsTemplate(token);
            setTariffs(updateTariffs);
        };
        fetchData();
    }, [token]);

    const findTariffs = (id) => {

        const tariff = tariffs.find(t => t.id === id);

        return (tariff);
    }
    const handleDetailsTariffs = (id) => {

        const tariff = findTariffs(id);

        const handleUpdateTariffTemplate = async (data, id) => {
            try {
                const response = await patchTariffTemplate(token, data, id);
                setAnswer(response.answer);

                if (response?.message?.includes('invalid_image')) {
                    setModalOptions({
                        title: `Детали тарифа ${tariff?.name}`,
                        content: {
                            tariff: findTariffs(id),
                            updateTariffTemplate: handleUpdateTariffTemplate,
                            message: {
                                severity: 'error',
                                message: "Файл не соответствует допустимому формату. Выберите изображение"
                            }
                        },
                    });
                }

                if (response.data) {
                    const updateTariffs = await getTariffsTemplate(token);
                    setTariffs(updateTariffs);
                    // setSelectedTariff(response.data);
                    handleCloseModal();
                }
            } catch (error) {
                console.error('Error updateTariffTemplate', error);
            }
        };

        setIsModalOpen(true);
        setModalType('detail_tariff')
        setModalOptions({
            title: `Детали тарифа ${tariff?.name}`,
            content: {
                tariff: findTariffs(id),
                updateTariffTemplate: handleUpdateTariffTemplate,
                message: answer
            },
        });

        // onClose: handleCloseModal,
        //     actions: [
        //     {
        //         icon: <DeleteRoundedIcon />,
        //         label: "Удалить",
        //         onClick: () => handleDeleteTariffs(params.row.id)
        //     }
        // ]
    };
    const handleDeleteTariffs = async (id) => {

        const tariff = findTariffs(id);

        const updateTariffTemplate = async () => {

            handleCloseModal();
            const updateDeleteTarriff = await deleteTariffsTemplate(token, id);
            console.log('\n ', updateDeleteTarriff);
        }

        setIsModalOpen(true);
        setModalType('ask_tariff')
        setModalOptions({
            title: `Вы уверены что хотите удалить тариф ${tariff?.name}?`,
            content: {
                success: updateTariffTemplate,
                reject: handleCloseModal
            },
        });
    };
    const handleAddTariff = () => {

        const handleAddTariffSubmit = async (e) => {

            e.preventDefault();

            const data = new FormData(e.target);

            const response = await addTariffsTemplate(token, data);

            setAnswer(response.answer);

            console.error(response?.message?.includes('invalid_image'))

            if (response?.message?.includes('invalid_image')) {
                setModalOptions({
                    title: "Создание нового тарифа",
                    content: {
                        onSubmit: handleAddTariffSubmit,
                        message: {
                            severity: 'error',
                            message: "Файл не соответствует допустимому формату. Выберите изображение"
                        }
                    },
                });
            }

            if (response.data) {
                const updateTariffs = await getTariffsTemplate(token);
                setTariffs(updateTariffs);
                // setSelectedTariff(response.data);
                handleCloseModal();
            }

        }

        setIsModalOpen(true);
        setModalType('add_tariff')
        setModalOptions({
            title: "Создание нового тарифа",
            content: {
                onSubmit: handleAddTariffSubmit,
            },
        });
    }

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setModalOptions(null);
        setAnswer(null);
        setModalType(null)
        setMessage(null)
    };
    const columns = [
        ...tariffsEditScheme,
        {
            field: "detail",
            headerName: "Смотреть детали тарифа",
            align: "center",
            headerAlign: "center",
            width: 200,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    startIcon={<DisplaySettingsOutlinedIcon/>}
                    color="info"
                    onClick={() => handleDetailsTariffs(params.row.id)}
                >
                    Редактировать тариф
                </Button>
            ),
        },
        {
            field: "delete",
            headerName: "Удалить тариф",
            align: "center",
            headerAlign: "center",
            width: 150,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    startIcon={<DeleteRoundedIcon/>}
                    color="error"
                    onClick={() => handleDeleteTariffs(params.row.id)}
                >
                    Удалить
                </Button>
            ),
        },
    ];

    return (
        <Box className="m-5 flex flex-col">
            {isModalOpen && (
                <BasicModal
                    open={isModalOpen}
                    handleClose={handleCloseModal}
                    title={modalOptions?.title}
                >
                    {
                        modalType === 'add_tariff' ?
                            <AddTariffTemplate
                                {...modalOptions.content}
                            />
                            : modalType === 'detail_tariff' ?
                                <DetailTariffs
                                    {...modalOptions.content}
                                />
                                : modalType === 'ask_tariff' &&
                                <AskUser {...modalOptions.content}/>
                    }
                </BasicModal>
            )}
            <Header title="Тарифы" subtitle="Управление шаблонами"/>
            <Alert severity="warning">
                Редактирование шаблонов не скажется на тарифах зарегистрированных компаний. Только на зарегистрированных
                после даты изменения тарифа.
            </Alert>
            <Box>
                <Button
                    variant="contained"
                    color="success"
                    onClick={handleAddTariff}
                    startIcon={<AddCardIcon/>}
                    className={`w-max`}
                >
                    Добавить новый тариф
                </Button>
            </Box>
            {tariffs.length > 0 && (
                <Box height="70vh" sx={tableColorsScheme}>
                    <DataGrid
                        rows={tariffs}
                        columns={columns}
                        getRowId={(row) => row.id}
                    />
                </Box>
            )}
        </Box>
    );
}